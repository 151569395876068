import React, { useState } from "react"
import query from "query-string"
import { Button, Box, settings } from "boostly-ui2"
import { update } from "../api"
import { getState } from "../state"
import EmojiMenu from "../components/EmojiMenu"
import Layout from "../components/Layout"
import Card from "../components/Card"
import Textarea from "../components/Textarea"
import Underscore from "../components/Underscore"
import ReviewButton from "../components/ReviewButton"
import Divider from "../components/Divider"
import Title from "../components/Title"

const PoorPage = props => {
  const [foodRating, setFoodXpRating] = useState()
  const [serviceRating, setServiceXpRating] = useState()
  const [feedback, setFeedback] = useState(``)
  const [feedbackSubmitted, setFeedbackStatus] = useState(false)
  const params = query.parse(props.location.search)

  const submitFeedback = () => {
    const updates = {
      foodRating,
      serviceRating,
      feedback,
    }
    const { reviewId } = getState()

    update({
      reviewId,
      updates,
    })
    setFeedbackStatus(true)
  }

  if (feedbackSubmitted) {
    return (
      <Layout bgShape={require(`../images/shape3.svg`)}>
        <Card
          gradient={{ to: settings.colors.red, from: settings.colors.purple }}
        >
          <Title size={4}>
            Thank You
            <span role={`img`} aria-label={`thanks`}>
              🙏
            </span>
          </Title>
          <Title pt={2}>
            You’re feedback has been received. We appreciate your time.
          </Title>
        </Card>
      </Layout>
    )
  }

  return (
    <Layout bgShape={require(`../images/shape3.svg`)}>
      <Card
        gradient={{ to: settings.colors.red, from: settings.colors.purple }}
      >
        <Title size={4}>
          Sorry to hear it{" "}
          <span role={`img`} aria-label={`disappointed face`}>
            😕
          </span>
        </Title>
        <Title pt={2}>We’d love to learn more so we can improve.</Title>
        <Title pt={2}>Will you rate the following?</Title>
        <Underscore />
        <Title pb={3} pl={2}>
          How was the food?
        </Title>
        <EmojiMenu>
          {({ Poor, Satisfactory, Amazing }) => (
            <>
              <Poor onSelect={setFoodXpRating} />
              <Satisfactory onSelect={setFoodXpRating} />
              <Amazing onSelect={setFoodXpRating} />
            </>
          )}
        </EmojiMenu>
        <Title pt={4} pb={3} pl={2}>
          How was the service?
        </Title>
        <EmojiMenu>
          {({ Poor, Satisfactory, Amazing }) => (
            <>
              <Poor onSelect={setServiceXpRating} />
              <Satisfactory onSelect={setServiceXpRating} />
              <Amazing onSelect={setServiceXpRating} />
            </>
          )}
        </EmojiMenu>
        <Title pt={4} pb={3} pl={2}>
          What else would you like us to know?
        </Title>
        <Textarea onChange={({ target }) => setFeedback(target.value)} />
        <Box pt={4}>
          <Button.second onClick={submitFeedback}>
            Submit Feedback
          </Button.second>
        </Box>
      </Card>
      <Box mt={`10px`} />

      <Divider>or</Divider>
      <Box mt={`40px`} />
      <Card
        gradient={{ to: settings.colors.red, from: settings.colors.purple }}
      >
        <Title size={4}>Need to blow off steam?</Title>
        <Title pt={2}>Leave a review.</Title>
        <Title pt={2} lineHeight={1.5}>
          Ideally, our promise that we’ll use your feedback to make your
          experience better next time is enough, but if not, we get it{` `}
          <span role={`img`} aria-label={`ok hand`}>
            👌
          </span>
        </Title>
        <Underscore />
        <ReviewButton placeId={params.googlePlaceId} />
      </Card>
    </Layout>
  )
}

export default PoorPage
